import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HomeContent from "../Components/Home/HomeContent";
import BannerContent from "../Components/Home/BannerContent";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import SponsorsModal from "../Components/Modal/SponsersModal";

const Home = () => {
  const location = useLocation();
  const [buyTicketModal, setBuyTicketModal] = useState(false);
  useEffect(() => {
    const { search } = location;
    if (!search) {
      window.scroll(0, 0);
    }
    setBuyTicketModal(true);
  }, []);


  return (
    <div className="header-banner-contain">

      <SponsorsModal
        open={buyTicketModal}
        setOpen={() => {
          setBuyTicketModal(false);
        }}
      />

      <Header />
      <BannerContent />
      <HomeContent />
      <Footer />

    </div>
  );
};

export default Home;
