import React, { useState, useEffect } from "react";
import Calender from "../../assets/images/calendar.webp";
import Location from "../../assets/images/map.webp";
import TimerSection from "../TimerSection";
import TicketBlack from "../../assets/images/ticket-black.webp";
import TicketGold from "../../assets/images/ticket-gold.webp";
import TicketWhite from "../../assets/images/ticket-white.webp";
import { useNavigate } from "react-router-dom";
import BuyTicketModal from "../Modal/BuyTicketModal";
const BannerContent = () => {
  const texts = [
    "Boost Your Sales",
    "Leverage AI for Marketing",
    "Sell High Tickets",
    "Become a Super Productive Human",
    "Launch Converting Funnels",
    "Become the Best Known in the Industry",
    "Build Your Personal Brand",
    "Create Engaging Content",
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change the duration as needed
    return () => clearInterval(interval);
  }, []);

  const navigation = useNavigate();

  //counter time start

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Target date: 25 May 2024 00:00:00
    const targetDate = new Date(2024, 4, 25); // Note: Months are zero-based, so 4 is May

    const interval = setInterval(() => {
      const currentDate = new Date();
      const difference = targetDate.getTime() - currentDate.getTime();

      if (difference <= 0) {
        clearInterval(interval);
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTime({ days, hours, minutes, seconds });
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const { days, hours, minutes, seconds } = time;

  //counter time end

  const [buyTicketModal, setBuyTicketModal] = useState(false);

  return (
    <div>
      <BuyTicketModal
        open={buyTicketModal}
        setOpen={() => {
          setBuyTicketModal(false);
        }}
      />
      <div className="home-banner ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="home-banner-content text-center mt-5">
                <h1 className="banner-white-text mb-0 fs-partner">
                  Learn How To
                </h1>
                <div className="text-animation-container mb-2">
                  {texts.map((text, idx) => (
                    <div
                      key={idx}
                      className={`textAnimation ${
                        idx === index ? "visible" : ""
                      }`}
                    >
                      <h1 className="textAnimationContent fs-partner">
                        {text}
                      </h1>
                    </div>
                  ))}
                </div>

                <p className="banner-para-content font-bold mb-4">
                  Transform your business by learning what’s{" "}
                  <span className="text-decoration-underline font-bold">
                    NEW
                  </span>
                  , what’s{" "}
                  <span className="text-decoration-underline font-bold">
                    HOT
                  </span>{" "}
                  , and what’s actually working{" "}
                  <span className="text-decoration-underline font-bold">
                    RIGHT NOW
                  </span>
                </p>
                <button
                  // onClick={() => setBuyTicketModal(true)}
                  onClick={() => navigation('/BuyTicket')}
                  class="btn yellow-btn"
                  data="Buy Ticket"
                >
                  <div class="figure">
                    <img className="ticket-gold" src={TicketBlack} alt="" />
                    <img className="ticket-white" src={TicketGold} alt="" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="date-location-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="date-location-banner">
                  <div className="second-layer"></div>
                  <div className="first-layer">
                    <div className="row justify-content-center">
                      <div className="col-lg-5">
                        <div className="flex-align-center justify-content-center">
                          <img
                            className="calender-icon"
                            src={Calender}
                            alt=""
                          />
                          <h5 className="date-text mb-0">25-26 May, 2024</h5>
                        </div>
                      </div>
                      <div className="col-lg-1 my-auto">
                        <div className="flex-align-center d-lg-block d-none justify-content-center">
                          <h5 className="date-text mb-0">|</h5>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="flex-align-center justify-content-center">
                          <img
                            className="calender-icon"
                            src={Location}
                            alt=""
                          />
                          <h5 className="date-text mb-0">Hilton, Chennai</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="third-layer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="container">
            {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
              <h1 className="textAnimationContent fs-2 text-center">Event Started</h1>
            ) : (
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3 col-6">
                      <div className="flex-align-center">
                        <div className="single-box gap-2">
                          <h5 className="gradient-text text-white">{days}</h5>
                          <h6 className="counter-text">Days</h6>
                        </div>
                        <h6 className="counter-text count-colun">:</h6>
                      </div>
                    </div>
                    <div className="col-lg-3  col-6">
                      <div className="flex-align-center">
                        <div className="single-box gap-2">
                          <h5 className="gradient-text text-white">{hours}</h5>
                          <h6 className="counter-text">Hours</h6>
                        </div>
                        <h6 className="counter-text count-colun">:</h6>
                      </div>
                    </div>
                    <div className="col-lg-3  col-6">
                      <div className="flex-align-center">
                        <div className="single-box gap-2">
                          <h5 className="gradient-text text-white">
                            {minutes}
                          </h5>
                          <h6 className="counter-text">Minutes</h6>
                        </div>
                        <h6 className="counter-text count-colun">:</h6>
                      </div>
                    </div>
                    <div className="col-lg-3  col-6">
                      <div className="flex-align-center">
                        <div className="single-box gap-2">
                          <h5 className="gradient-text text-white">
                            {seconds}
                          </h5>
                          <h6 className="counter-text">Seconds</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="">
        <TimerSection />
      </div> */}
    </div>
  );
};

export default BannerContent;
