import React, { useState } from "react";
import CustomModal from "./CustomModal";

import TicketBlack from "../../assets/images/ticket-black.webp";
import TicketGold from "../../assets/images/ticket-gold.webp";
import SponsorsImg from "../../assets/images/sponsors-img.webp";
import SponsorsImgMob from "../../assets/images/sponsors-img-2.webp";
import BuyTicketModal from "./BuyTicketModal";
import { useNavigate } from "react-router-dom";

const SponsorsModal = ({ open, setOpen }) => {

    const [buyTicketModal, setBuyTicketModal] = useState(false);

    const navigation = useNavigate();
    return (
        <div>
            <BuyTicketModal
                open={buyTicketModal}
                setOpen={() => {
                    setBuyTicketModal(false);
                }}
            />
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content sponsors-modal-content">
                    <div className="close-btn">
                        <i
                            className="fa-regular fa-circle-xmark"
                            onClick={() => setOpen(false)}
                        ></i>
                    </div>

                    <div className="sponsors-logo">
                        <div className="container">
                            <div className="text-center">
                                <h1 className="textAnimationContent sponsors-text font-bold fs-2 mt-3 mb-4">Thank you to all our Sponsors</h1>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12 mb-3">
                                    <img className="w-100 h-auto d-lg-block d-none" src={SponsorsImg} alt="" />
                                    <img className="w-100 h-auto d-lg-none d-block" src={SponsorsImgMob} alt="" />
                                </div>
                                <div className="col-lg-12 ">
                                    <div className="text-center mb-2">
                                        <button
                                            // onClick={() => {
                                            //     setOpen(false)
                                            //     setBuyTicketModal(true)
                                            // }}
                                            onClick={() => navigation('/BuyTicket')}
                                            class="btn yellow-btn"
                                            data="Buy Ticket"
                                        >
                                            <div class="figure">
                                                <img className="ticket-gold" src={TicketBlack} alt="" />
                                                <img className="ticket-white" src={TicketGold} alt="" />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default SponsorsModal;
