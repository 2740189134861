import React, { useEffect } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import Award1 from '../../assets/images/award-1.webp'
import Award2 from '../../assets/images/award-2.webp'
import Award3 from '../../assets/images/award-3.webp'
import Award4 from '../../assets/images/award-4.webp'
import Award5 from '../../assets/images/award-5.webp'
import Award6 from '../../assets/images/award-6.webp'
import Award7 from '../../assets/images/award-7.webp'
import Award8 from '../../assets/images/award-8.webp'
import Award9 from '../../assets/images/award-9.webp'
import Award10 from '../../assets/images/award-10.webp'
import Award11 from '../../assets/images/award-11.webp'
import Award12 from '../../assets/images/award-12.webp'
import Award13 from '../../assets/images/award-13.webp'
import Award14 from '../../assets/images/award-14.webp'
import Award15 from '../../assets/images/award-15.webp'
import Award16 from '../../assets/images/award-16.webp'
import Award17 from '../../assets/images/award-17.webp'
import Award18 from '../../assets/images/award-18.webp'
import Award19 from '../../assets/images/award-19.webp'
import Award20 from '../../assets/images/award-20.webp'
import Award21 from '../../assets/images/award-21.webp'
import Award22 from '../../assets/images/award-22.webp'
import Award23 from '../../assets/images/award-23.webp'
import Award24 from '../../assets/images/award-24.webp'
import Award25 from '../../assets/images/award-25.webp'
import Award26 from '../../assets/images/award-26.webp'
import Award27 from '../../assets/images/award-27.webp'
import Award28 from '../../assets/images/award-28.webp'
import BGAWARD from "../../assets/images/bg-award-eagle.webp";
import Award29 from '../../assets/images/award-29.webp'
import Award30 from '../../assets/images/award-30.webp'
import Award31 from '../../assets/images/award-31.webp'
import Award32 from '../../assets/images/award-32.webp'
import Award33 from '../../assets/images/award-33.webp'
import Award34 from '../../assets/images/award-34.webp'
import Award35 from '../../assets/images/award-35.webp'
import ArrowGold from "../../assets/images/arrow-gold.webp"
import ArrowBlack from "../../assets/images/arrow-black.webp"
const AwardsContent = () => {

    const location = useLocation();
    const navigation = useNavigate();
    useEffect(() => {
        const { search } = location;
        const containerId = search.substring(4); // Remove the leading slash
        const element = document.getElementById(containerId);

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center",
            });
        }
    }, [location]);

    return (
        <div id="InfluencerAward">
            <div className="my-5" >
                <div className="">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award1} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">FOOD</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award2} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">TRAVEL</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 ">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award3} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">LIFESTYLE</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award4} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">FITNESS</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award5} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">HEALTH</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award6} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">COOKING</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award7} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">COMEDY</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award8} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">MINDSET & PSYCHOLOGY</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award9} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">LIFE COACH</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award10} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">COACHES (OTHER)</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award11} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">FINANCE</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award12} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">TECH EDUCATORS</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award13} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">PROFESSIONALS (DOCTORS, LAWYERS & ETC)</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award14} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">ENTREPRENEURS AND BUSINESS PEOPLE</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award15} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">BEST STORYTELLER</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award16} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">THE DISRUPTOR OF THE YEAR</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award17} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">MOST IMPACTFUL AGRI CREATOR</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award18} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">GAMING</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award19} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">TECH CREATOR - TECHNOLOGY & HARDWARE</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award20} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">QUALITY PRODUCTION (TECH) - VIDEO & EDITS</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award21} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">BEAUTY & FASHION</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award22} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">COUPLE INFLUENCER OF THE YEAR</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award23} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">POSITIVE IMPACT CREATORS / SHINING STARS</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award24} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">SOCIAL SERVICE / HUMANITY AWARD</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award25} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">AUTO-MOTIVE INFLUENCER</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award26} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">ENTERTAINER OF THE YEAR</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award27} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">CELEBRITY CREATOR OF THE YEAR</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="awards-card-container">
                                    <img className="award-categ-img" src={Award28} alt="" />
                                    <div className="rounded-border-content-awards">
                                        <h2 className="table-para-content-awards mb-0">NANO INFLUENCER OF THE YEAR</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-5">
                            <button class="btn yellow-btn-award" 
                            // onClick={()=>navigation('/InfluencerAwardNominationForm')} 
                            >Nominations has been closed{" "}
                                {/* <div class="figure">
                                    <img class="ticket-gold" src={ArrowBlack} alt="" />
                                    <img class="ticket-white" src={ArrowGold} alt="" />
                                </div> */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="height-award-1 mb-5" id="AgencyAward">
                <div>
                    <div className="position-relative">
                        <div className="">
                            <img className="bg-award-eagle mt-0" src={BGAWARD} alt="" />
                        </div>
                        <div className="ab-eagle-2"><h1 className="attend-card-text-white">BEST DIGITAL AGENCY AWARDS</h1></div>
                    </div>
                </div>
                <div className="container pt-5" >
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award29} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">CAMPAIGN EFFECTIVENESS AWARD</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award30} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST USE OF AI IN CLIENT CAMPAIGN</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award31} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST PR CAMPAIGN</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award32} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST PPC CAMPAIGN</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award33} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST SOCIAL MEDIA CAMPAIGN</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award34} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST CREATIVE CAMPAIGN - AGENCY AWARD</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="awards-card-container">
                                <img className="award-categ-img" src={Award35} alt="" />
                                <div className="rounded-border-content-awards">
                                    <h2 className="table-para-content-awards mb-0">BEST DIGITAL START-UP OF THE YEAR</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-5">
                        <button class="btn yellow-btn-award"
                        //  onClick={()=>navigation('/AgencyAwardNominationForm')}
                         >Nominations has been closed{" "}
                            {/* <div class="figure">
                                <img class="ticket-gold" src={ArrowBlack} alt="" />
                                <img class="ticket-white" src={ArrowGold} alt="" />
                            </div> */}
                        </button>
                    </div>
                </div>

            </div>

        </div >
    );
};

export default AwardsContent;
